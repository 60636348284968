import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Fragment } from 'react';
import { colors } from '../../styles/colors';
import { subtitleFont } from '../../styles/typography';
import Icon from '../Icons/Icon';
import { icons } from '../Icons/icons';
import { ButtonWrapper } from '../ButtonWrapper/ButtonWrapper';

const styles = {
  border: `1px solid ${colors.gray.light}`,
  borderRadius: 4,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '14px 10px',
  '.paginator-text': {
    ...subtitleFont('md', 'sans'),
    color: colors.gray.darkest,
    fontWeight: 'normal',
    userSelect: 'none',
    paddingLeft: 16,
    paddingRight: 16,
  },
  '.button-wrapper': {
    '.udt-icon': {
      path: {
        fill: colors.cyan.dark,
      },
    },
    '&.disabled': {
      cursor: 'default',
      '.udt-icon': {
        path: {
          fill: colors.gray.lighten,
        },
      },
    },
    '&.chevron-right': {
      marginLeft: 'auto',
    },

    '&.chevron-left': {
      marginRight: 'auto',
    },
    '.hidden-icon': {
      visibility: 'hidden',
    },
  },
};

const splitButtonsStyles = {
  ...styles,
  border: 'unset',
  padding: 'unset',
  '.button-wrapper': {
    ...styles['.button-wrapper'],
    border: `1px solid ${colors.gray.light}`,
    padding: '14px 10px',
    borderRadius: 4,
    '&.chevron-right': {
      ...styles['.button-wrapper']['&.chevron-right'],
      marginLeft: 'unset',
    },
    '&.chevron-left': {
      ...styles['.button-wrapper']['&.chevron-left'],
      marginRight: 'unset',
    },
  },
};

export const Paginator = ({
  visibleRecordsCount,
  totalRecordsCount,
  previousText,
  nextText,
  showRecordCount,
  splitButtons,
  isFirstPage,
  isLastPage,
  onPrevClick,
  onNextClick,
  className,
}) => {
  const handlePrevClick = () => {
    if (isFirstPage) return;
    onPrevClick();
  };

  const handleNextClick = () => {
    if (isLastPage) return;
    onNextClick();
  };

  return (
    <div css={splitButtons ? splitButtonsStyles : styles} className={className}>
      <ButtonWrapper
        onClick={handlePrevClick}
        className={`button-wrapper chevron-left ${isFirstPage ? 'disabled' : ''}`}
      >
        <Icon icon={icons.chevronLeft} size={18} />
        {previousText ? (
          <Fragment>
            {previousText} <Icon icon={icons.chevronLeft} size={18} className="hidden-icon" />
          </Fragment>
        ) : null}
      </ButtonWrapper>
      <div className="paginator-text">
        {showRecordCount ? `${visibleRecordsCount} / ${totalRecordsCount}` : null}
      </div>
      <ButtonWrapper
        onClick={handleNextClick}
        className={`button-wrapper chevron-right ${isLastPage ? 'disabled' : ''}`}
      >
        {nextText ? (
          <Fragment>
            <Icon icon={icons.chevronLeft} size={18} className="hidden-icon" /> {nextText}
          </Fragment>
        ) : null}
        <Icon icon={icons.chevronRight} size={18} />
      </ButtonWrapper>
    </div>
  );
};

Paginator.propTypes = {
  visibleRecordsCount: PropTypes.number,
  totalRecordsCount: PropTypes.number,
  previousText: PropTypes.string,
  nextText: PropTypes.string,
  showRecordCount: PropTypes.bool,
  splitButtons: PropTypes.bool,
  isFirstPage: PropTypes.bool.isRequired,
  isLastPage: PropTypes.bool.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Paginator.defaultProps = {
  className: '',
  visibleRecordsCount: 0,
  totalRecordsCount: 0,
  previousText: '',
  nextText: '',
  showRecordCount: true,
  splitButtons: false,
};
