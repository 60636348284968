/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { colors, colorTheme } from '../../styles/colors';
import { bodyFont } from '../../styles/typography';
import Icon from '../Icons/Icon';
import { icons } from '../Icons/icons';
import countryInitialState from './country.config';

const styles = {
  position: 'relative',
  '.selector': {
    background: '#fff',
    borderRadius: 4,
    cursor: 'pointer',
    padding: 4,
    border: `1px solid ${colors.gray.lighten}`,
    '.chevron-open, .chevron-close': { marginLeft: 4, marginRigth: 0 },
    '.chevron-open': { transform: 'rotate(180deg)', transition: 'transform 0.2s' },
    '.chevron-close': { transform: 'rotate(0deg)', transition: 'transform 0.2s' },
  },
  '.flag': {
    width: 36,
    verticalAlign: 'middle',
  },
  '.options': {
    position: 'absolute',
    top: 33,
    left: 0,
    width: 82,
    paddingBottom: 12,
    borderRadius: 4,
    zIndex: 3,
    background: '#fff',
    border: `1px solid ${colors.gray.lighten}`,
    '.option': {
      marginTop: 12,
      paddingLeft: 4,
      paddingRight: 4,
      border: 0,
      cursor: 'pointer',
      background: '#fff',
    },
    '.short-name': {
      marginLeft: 8,
      ...bodyFont('sm'),
    },
  },
  '.click-outside': {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 2,
  },
};

const CountrySelector = (props) => {
  const { disabled, selected, clicked, className } = props;

  const initialSetup = (flagsData) => {
    return flagsData.filter((flag) => !disabled.includes(flag.country));
  };

  const [selectedState, setSelectedState] = useState(selected);
  const [open, setOpen] = useState(false);
  const [flags] = useState(initialSetup(countryInitialState));

  const selectFlag = (flag) => {
    setSelectedState(flag.country);
    setOpen(!open);
    clicked(flag);
  };
  const selectedFlag = flags.find((flag) => flag.country === selectedState);

  const flagsList = flags
    .filter((flag) => flag.country !== selectedState)
    .map((item) => (
      <button
        type="button"
        className={`option ${item.className}`}
        key={item.country}
        onClick={() => selectFlag(item)}
      >
        <img className="flag" src={item.flagUrl} alt={item.name} />
        <span className="short-name">{item.name.slice(0, 3).toUpperCase()}</span>
      </button>
    ));

  return (
    <span css={styles} className={className}>
      <button type="button" className="selector" onClick={() => setOpen(!open)}>
        <span className="selected-flag">
          {selectedFlag && (
            <img
              className={`flag ${selectedFlag.className}`}
              src={selectedFlag.flagUrl}
              alt={selectedFlag.name}
            />
          )}
        </span>
        <Icon
          className={`chevron-${open ? 'open' : 'close'}`}
          size="16"
          icon={icons.chevronBottom}
          color={colorTheme.interactive}
        />
      </button>
      {open && <span className="options">{flagsList}</span>}
      {open && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className="click-outside" onClick={() => setOpen(!open)} />
      )}
    </span>
  );
};

CountrySelector.propTypes = {
  selected: PropTypes.oneOf(['ar', 'cl', 'co', 'br', 'mx', 'us']).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.arrayOf(PropTypes.string),
  clicked: PropTypes.func.isRequired,
};

CountrySelector.defaultProps = {
  className: '',
  disabled: ['mx', 'us'],
};

export default CountrySelector;
