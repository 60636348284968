import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { useState } from 'react';
import { ButtonWrapper } from '../ButtonWrapper/ButtonWrapper';
import Icon from '../Icons/Icon';
import { colors, colorTheme } from '../../styles/colors';
import { icons } from '../Icons/icons';

const styles = {
  position: 'relative',
  display: 'table',
  '.dropdown-options-wrapper': {
    position: 'absolute',
    display: 'none',
    flexDirection: 'column',
    zIndex: 1,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    paddingTop: '10px',
    paddingBottom: '10px',
    background: 'white',
    '&.display-b-r': {
      top: '100%',
      left: 0,
      marginTop: '5px',
    },
    '&.display-b-l': {
      top: '100%',
      right: 0,
      marginTop: '5px',
    },
    '&.display-t-r': {
      bottom: '100%',
      left: 0,
      marginBottom: '5px',
    },
    '&.display-t-l': {
      bottom: '100%',
      right: 0,
      marginBottom: '5px',
    },
    '&.active': {
      display: 'flex',
    },
    '.dropdown-options-item': {
      border: '0px',
      display: 'flex',
      background: 'white',
      padding: '12px 16px',
      color: colorTheme.textDefault,
      zIndex: 3,
      whiteSpace: 'nowrap',
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        background: colors.gray.lightest,
      },
    },
  },
  '.dropdown-button': {
    display: 'block',
  },

  '.danger': {
    color: colorTheme.hintDanger,
  },
  '.success': {
    color: colorTheme.hintSuccess,
  },
  '.click-outside': {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 2,
  },
};

let variants = {
  primary: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colorTheme.interactive,
    backgroundColor: colorTheme.interactive,
    color: '#fff',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'rgba(204, 204, 204, 0.5)',
      color: colorTheme.interactive,
      borderColor: colorTheme.interactive,
    },
  },
  secondary: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colorTheme.interactive,
    backgroundColor: 'transparent',
    color: colorTheme.interactive,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: colorTheme.interactive,
      color: colorTheme.interactive,
    },
  },
  link: {
    borderWidth: '0px',
    backgroundColor: 'transparent',
    color: colorTheme.interactive,
    borderRadius: '50%',
  },
};

const ContextMenu = ({ className, options, variant, color, display, iconSize }) => {
  const [visible, setVisible] = useState(false);

  const onClickOptionHandler = (onClick) => {
    setVisible(false);
    onClick();
  };

  const wrapperRef = React.createRef();

  const handleClickInside = () => {
    setVisible(!visible);
  };

  if (color) {
    // Primary
    variants.primary.backgroundColor = color.dark;
    variants.primary.color = '#fff';
    variants.primary.borderColor = color.dark;
    variants.primary['&:hover'].backgroundColor = color.medium;
    variants.primary['&:hover'].color = '#fff';
    variants.primary['&:hover'].borderColor = color.medium;

    // Secondary
    variants.secondary.borderColor = color.dark;
    variants.secondary.color = color.dark;
    variants.secondary['&:hover'].backgroundColor = color.lightest;
    variants.secondary['&:hover'].color = color.dark;

    // Link
    variants.link.color = color.dark;
  }

  let variantStyle = variants.link;
  let iconColor = '#fff';
  if (variant) {
    if (variant === 'primary') {
      variantStyle = variants.primary;
      iconColor = '#fff';
    }

    if (variant === 'secondary') {
      variantStyle = variants.secondary;
      iconColor = color.dark;
    }

    if (variant === 'link') {
      variantStyle = variants.link;
      iconColor = color.dark;
    }
  }

  const displays = {
    'bottom-right': 'display-b-r',
    'bottom-left': 'display-b-l',
    'top-right': 'display-t-r',
    'top-left': 'display-t-l',
  };

  const displayStyle = displays[display] || 'display-b-r';

  const allowedOptions = options.filter((option) => option.isAllowedTo);

  return (
    <div css={styles} ref={wrapperRef} className={className}>
      <ButtonWrapper onClick={handleClickInside} css={variantStyle}>
        <Icon
          icon={icons.contextMenu}
          size={iconSize}
          className="dropdown-button"
          color={iconColor}
        />
      </ButtonWrapper>
      <div className={`dropdown-options-wrapper ${visible ? 'active' : ''} ${displayStyle}`}>
        {allowedOptions &&
          allowedOptions.map((option) => (
            <button
              type="button"
              key={option.id}
              className="dropdown-options-item"
              onClick={() => onClickOptionHandler(option.onClick)}
            >
              <span className={option.danger ? 'danger' : option.success ? 'success' : ''}>
                {option.text}
              </span>
            </button>
          ))}
        {visible && (
          <div className="click-outside" onClick={() => setVisible(!visible)} role="presentation" />
        )}
      </div>
    </div>
  );
};

ContextMenu.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      onClick: PropTypes.func,
      text: PropTypes.string,
      operation: PropTypes.string,
      danger: PropTypes.bool,
      success: PropTypes.bool,
      isAllowedTo: PropTypes.bool,
    }),
  ).isRequired,
  variant: PropTypes.string,
  color: PropTypes.object,
  display: PropTypes.string,
  iconSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ContextMenu.defaultProps = {
  className: '',
  variant: 'link',
  color: colors.cyan,
  display: 'bottom-rigth',
  iconSize: 16,
};

export default ContextMenu;
