import Axios from 'axios';
import { domainResolver } from '../../../../utils';
import { alpha2List } from '../../../../utils/country-alpha2';
import { getPartner } from '../../../../utils/fluxes';
import { getToken } from '../../../../utils/recaptcha';
import { getSocket } from '../../../../utils/socket';
import { commonKeysInAdditionalData } from '../../../analytics/analytics.config';
import { getFilteredAdditionalData } from '../../../analytics/analytics.service';
import { ANALYTICS_ADD_ADDITIONAL_DATA } from '../../../analytics/store/analytics.action-types';
import { analyticsPushEvent } from '../../../analytics/store/analytics.actions';
import { handleServerError } from '../../../main/store/actions';
import {
  ADD_INTERACTION,
  ADD_INTERACTION_FAIL,
  ADD_INTERACTION_SUCCESS,
  ASSIGN_LEAD_TO_RPA,
  ASSIGN_LEAD_TO_RPA_FAIL,
  ASSIGN_LEAD_TO_RPA_SUCCESS,
  RESET_RESULTS_LOADING,
  SAVE_OPTIONS,
  SAVE_OPTIONS_FAIL,
  SAVE_OPTIONS_SUCCESS,
  SAVE_PLAN,
  SAVE_PLANS,
  SAVE_PLANS_FAIL,
  SAVE_PLANS_FINISH,
  SAVE_PLANS_SUCCESS,
  SELECT_DEDUCTIBLE_SELECTED,
  UPDATE_LEAD_STATUS,
  UPDATE_LEAD_STATUS_FAIL,
  UPDATE_LEAD_STATUS_SUCCESS,
} from './results.action-types';

export const selectDeductibleSelected = (plan, deductible) => async (dispatch, getState) => {
  const plans = getState().car.results.plans;
  plans[plan.index].deductibleSelected = deductible;
  dispatch({
    type: SELECT_DEDUCTIBLE_SELECTED,
    payload: plans,
  });
};

export const resetResultsLoading = () => async (dispatch) => {
  dispatch({
    type: RESET_RESULTS_LOADING,
  });
};

export const savePlan = (plan) => async (dispatch) => {
  dispatch({
    type: SAVE_PLAN,
    payload: plan,
  });
};

export const addInteraction = (planSelected) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_INTERACTION,
    });
    const partner = getPartner();
    const reCaptchaToken = await getToken(ADD_INTERACTION);
    const body = {
      lead: planSelected.leadId,
      interactionAcronym: planSelected.interactionAcronym,
      metadata: planSelected.metadata,
      requesterPersonId: planSelected.responsibleLead,
    };

    await Axios.post(`/cars/lead/interaction`, body, {
      headers: {
        'x-recaptcha-token': reCaptchaToken,
        'x-recaptcha-action': ADD_INTERACTION,
        'x-partner': partner,
      },
    });

    dispatch({
      type: ADD_INTERACTION_SUCCESS,
    });
  } catch (e) {
    dispatch(handleServerError(e.response.status));
    dispatch({ type: ADD_INTERACTION_FAIL });
  }
};

export const updateLeadStatus = (data) => async (dispatch) => {
  const { leadId, contactStatus } = data;
  try {
    dispatch({
      type: UPDATE_LEAD_STATUS,
    });
    const partner = getPartner();
    const reCaptchaToken = await getToken(UPDATE_LEAD_STATUS);
    const body = {
      contactStatus,
    };

    await Axios.put(`/cars/lead/${leadId}/status`, body, {
      headers: {
        'x-recaptcha-token': reCaptchaToken,
        'x-recaptcha-action': UPDATE_LEAD_STATUS,
        'x-partner': partner,
      },
    });

    dispatch({
      type: UPDATE_LEAD_STATUS_SUCCESS,
    });
  } catch (error) {
    dispatch(handleServerError(error.response.status));
    dispatch({ type: UPDATE_LEAD_STATUS_FAIL });
  }
};

export const assignLeadToRpa = ({
  leadId,
  companyName,
  productName,
  productValue,
  country,
}) => async (dispatch) => {
  try {
    await dispatch({
      type: ASSIGN_LEAD_TO_RPA,
    });
    const partner = getPartner();
    const reCaptchaToken = await getToken(ASSIGN_LEAD_TO_RPA);
    const body = {
      leadId,
      companyName,
      productName,
      productValue,
      country,
    };

    await Axios.post('/cars/assignments/to-rpa', body, {
      headers: {
        'x-recaptcha-token': reCaptchaToken,
        'x-recaptcha-action': ASSIGN_LEAD_TO_RPA,
        'x-partner': partner,
      },
    });

    await dispatch({
      type: ASSIGN_LEAD_TO_RPA_SUCCESS,
    });
  } catch (e) {
    dispatch(handleServerError(e.response.status));
    dispatch({ type: ASSIGN_LEAD_TO_RPA_FAIL });
  }
};

export const loadOptions = () => async (dispatch, getState) => {
  dispatch({
    type: SAVE_OPTIONS,
  });
  const country = domainResolver();
  const riskType = getState().wizard.riskType;
  const campaign = getState().main.mkt.medium;
  const channel = getState().main.mkt.source;

  let res;
  try {
    if (country === alpha2List.BR) {
      res = await Axios.get('/cars/options', {
        params: {
          campaign,
          channel,
        },
      });
    } else if (country === alpha2List.AR) {
      const leadId = getState().car.lead.leadId;
      res = await Axios.get(`/cars/options/${leadId}`);
    } else {
      const carId = getState().car.lead.carId;
      res = await Axios.get(`/cars/options/${riskType}/${carId}`);
    }
    dispatch({ type: SAVE_OPTIONS_SUCCESS, payload: res.data });
  } catch (e) {
    dispatch(analyticsPushEvent('trackError', 'server error', 'error-campo', { errorCode: 500 }));
    dispatch({ type: SAVE_OPTIONS_FAIL });
  }
};

const plansMap = (plan, index) => {
  let deductibleSelected = plan.deductibleSelected;
  if (plan.deductibles && !deductibleSelected) {
    deductibleSelected = plan.deductibles[plan.deductibles.length - 1];
  }
  return {
    ...plan,
    deductibleSelected,
    index,
  };
};

export const sendTrackCtzEvent = (data = {}) => async (dispatch, getState) => {
  const analytics = getState().analytics;

  dispatch(
    analyticsPushEvent(
      'trackCtz',
      'ctz-ok',
      'cotizacion',
      {
        ...getFilteredAdditionalData(analytics.pageAdditionalData, [
          ...commonKeysInAdditionalData,
          'lead_id',
          'cant_coberturas',
        ]),
        ...data,
      },
      'cotizador',
    ),
  );
};

/**
 * sortPlans
 * @description Sort a plans array by the featured companies
 * @param  {string} plansToSaved plans array
 * @param  {string} featuredCompany featured companies
 * @returns {array} sorted plans array
 */
const sortPlans = (plansToSaved, featuredCompany) => {
  if (plansToSaved.length === 0 || !featuredCompany || featuredCompany.length === 0) {
    return plansToSaved;
  }
  let orderedPlans = plansToSaved;
  const orderPlans = [...featuredCompany];
  orderPlans.reverse().forEach((companyKey) => {
    orderedPlans = orderedPlans.sort((planA, planB) => {
      if (planA.carrier.keyName === companyKey) {
        return -1;
      }
      if (planB.carrier.keyName === companyKey) {
        return 1;
      }
      return 0;
    });
  });
  return orderedPlans;
};

export const loadPlans = (leadId, carId, carriers) => async (dispatch, getState) => {
  dispatch({
    type: SAVE_PLANS,
  });
  let sendEvent = true;
  let plansSaved = [];
  const riskType = getState().wizard.riskType;
  const featuredCompany = getState().main.context.featuredCompany;
  const country = domainResolver();
  const reCaptchaToken = await getToken(SAVE_PLANS);
  const partner = getPartner();

  const socket = getSocket();

  socket.on('set_plans', (plans) => {
    plansSaved = [alpha2List.BR, alpha2List.AR].includes(country)
      ? plans
      : plansSaved.concat(plans).map(plansMap);

    if (featuredCompany && getPartner() === null) {
      plansSaved = sortPlans(plansSaved, featuredCompany);
    }
    dispatch({ type: SAVE_PLANS_SUCCESS, payload: plansSaved });
    dispatch({
      type: ANALYTICS_ADD_ADDITIONAL_DATA,
      payload: {
        cant_coberturas: plansSaved.length,
      },
    });
    if (plans && plans.length && sendEvent) {
      sendEvent = false;
      dispatch(
        sendTrackCtzEvent({
          cant_coberturas: plansSaved.length,
        }),
      );
    }
  });

  socket.on('set_plans_finished', () => {
    dispatch({ type: SAVE_PLANS_FINISH });
    socket.disconnect();
  });

  socket.on('recaptcha_validation_fail', () => {
    dispatch(handleServerError(403));
    dispatch({ type: SAVE_PLANS_FAIL });
    socket.disconnect();
  });

  let getPlansEmitted = false;
  socket.on('connect', () => {
    if (!getPlansEmitted) {
      socket.emit('get_plans', carriers, {
        riskType,
        leadId,
        carId,
        country,
        reCaptchaToken,
        reCaptchaAction: SAVE_PLANS,
        clientId: Axios.defaults.headers.common['x-client-id'],
        partner,
      });
      getPlansEmitted = true;
    }
  });
};
