/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { fieldBaseStyles } from '../../../styles/baseStyles';
import { colors } from '../../../styles/colors';
import Icon from '../../Icons/Icon';
import { icons } from '../../Icons/icons';
import Input from '../Input/Input';

const styles = {
  marginBottom: 16,
  position: 'relative',
  '.container': {
    display: 'flex',
    '.countryCode': {
      marginBottom: 0,
      input: {
        paddingRight: 3,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    '.areaCode': {
      marginBottom: 0,
      width: '35%',
      input: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: 0,
        borderRight: 0,
      },
    },
    '.number': {
      marginBottom: 0,
      width: '55%',
      input: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
  },
  '.label': {
    ...fieldBaseStyles.label,
    '.required': {
      color: colors.red.medium,
      position: 'absolute',
      left: '-0.633em',
    },
  },
  '&.error-phone': {
    '.message-phone': {
      color: colors.red.dark,
      display: 'flex',
      fontSize: 13,
      marginTop: 4,
      marginBottom: 0,
      svg: {
        marginRight: 4,
      },
    },
    input: {
      border: `1px solid ${colors.red.dark}`,
    },
  },
};

const Phone = ({
  className,
  label,
  placeholder,
  required,
  disabled,
  value,
  onChange,
  onBlur,
  error,
  maxLength,
}) => {
  const [phone, setPhone] = useState(value);

  const handleChange = (e, section) => {
    if (maxLength[section] && maxLength[section] < e.target.value.length) {
      return;
    }
    const value = { ...phone, [section]: e.target.value };
    setPhone(value);
    onChange(value);
  };

  const handleBlur = (e) => {
    onBlur(e);
  };

  const displayMsg = () => {
    if (error) return error;
    return null;
  };

  return (
    <div css={styles} className={`${className} ${error ? 'error-phone' : ''} `}>
      {label && (
        <label className="label">
          {required && <span className="required">*</span>} {label}
        </label>
      )}

      <div className="container">
        <Input
          type="tel"
          className="countryCode"
          label=""
          value={phone.countryCode ? phone.countryCode : ''}
          disabled={disabled === true || disabled?.countryCode === true}
          size={phone.countryCode?.length}
          onBlur={handleBlur}
        />
        <Input
          type="number"
          className="areaCode"
          label=""
          placeholder={placeholder.areaCode}
          value={phone.areaCode ? phone.areaCode : ''}
          disabled={disabled === true || disabled?.areaCode === true}
          onChange={(e) => handleChange(e, 'areaCode')}
          onBlur={handleBlur}
        />
        <Input
          type="number"
          className="number"
          label=""
          placeholder={placeholder.number}
          value={phone.number ? phone.number : ''}
          disabled={disabled === true || disabled?.number === true}
          onChange={(e) => handleChange(e, 'number')}
          onBlur={handleBlur}
        />
      </div>

      {error && (
        <span className="message-phone">
          <Icon icon={icons.error} color={colors.red.dark} />
          <span>{displayMsg()}</span>
        </span>
      )}
    </div>
  );
};

Phone.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.shape({
    areaCode: PropTypes.string,
    number: PropTypes.string,
  }),
  maxLength: PropTypes.shape({
    areaCode: PropTypes.number,
    number: PropTypes.number,
  }),
  required: PropTypes.bool,
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      countryCode: PropTypes.bool,
      areaCode: PropTypes.bool,
      number: PropTypes.bool,
    }),
  ]),
  value: PropTypes.exact({
    type: PropTypes.string,
    countryCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    areaCode: PropTypes.number,
    number: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
};

Phone.defaultProps = {
  className: '',
  label: '',
  placeholder: {
    areaCode: '',
    number: '',
  },
  maxLength: {
    areaCode: undefined,
    number: undefined,
  },
  required: false,
  disabled: false,
  onChange: () => {},
  onBlur: () => {},
  error: null,
};

export default Phone;
