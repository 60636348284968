/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import InputMask from 'react-input-mask';
import { baseStyles, fieldBaseStyles } from '../../../styles/baseStyles';
import { colors as udtColors } from '../../../styles/colors';
import { base } from '../../../styles/typography';
import Icon from '../../Icons/Icon';
import { icons } from '../../Icons/icons';
import { ButtonWrapper } from '../../ButtonWrapper/ButtonWrapper';

const colors = {
  normal: udtColors.gray.light,
  focus: udtColors.cyan.dark,
  error: udtColors.red.dark,
};

const borderError = {
  borderColor: colors.error,
  '&:focus': {
    borderColor: colors.normal,
  },
};

const borderFocus = {
  borderColor: colors.focus,
  '&:focus': {
    borderColor: colors.focus,
  },
};

const styles = {
  fontFamily: base.fontFamily,
  marginBottom: 16,
  position: 'relative',
  '.label': {
    ...fieldBaseStyles.label,
    '.required': {
      color: udtColors.red.medium,
      position: 'absolute',
      left: '-0.633em',
    },
  },
  input: {
    ...baseStyles.input,
    '&[type=number]': {
      '&::-webkit-outer-spin-button,&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      MozAppearance: 'textfield',
    },
  },
  textarea: {
    ...baseStyles.input,
  },
  '.message': {
    display: 'flex',
    fontSize: 13,
    marginTop: 4,
    marginBottom: 0,
    svg: {
      marginRight: 4,
    },
  },
  '&.error': {
    input: {
      ...borderError,
      '&:focus': {
        ...borderFocus,
      },
    },
    textarea: { ...borderError },
    '.message': {
      color: colors.error,
    },
  },
  '&.info': {
    input: { ...borderFocus },
    textarea: { ...borderFocus },
    '.message': {
      color: colors.focus,
    },
  },
  '.input-container': {
    position: 'relative',
    '.input-icon': {
      position: 'absolute',
      top: 12,
      right: 12,
      zIndex: 4,
    },
    '.input-icon + input': {
      paddingRight: 40,
    },
  },
};

const chooseRender = (Tag, mask, render) => {
  if (mask) {
    // TODO: evitar retotar una arrow function, crear una funcion con nombre
    // eslint-disable-next-line react/display-name
    return (props) => <InputMask mask={mask} {...props} />;
  }

  if (render) {
    return (props) => render(props);
  }
  // TODO: evitar retotar una arrow function, crear una funcion con nombre
  // eslint-disable-next-line react/display-name
  return (props) => <Tag {...props} />;
};

/**
 * UI-Kit input component
 */
const Input = ({
  tag: Tag,
  label,
  required,
  value,
  info,
  error,
  disabled,
  className,
  onFocus,
  onChange,
  onBlur,
  icon,
  onIconClick,
  children,
  render,
  mask,
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  const handleFocus = (e) => {
    setFocus(true);
    onFocus(e);
  };
  const handleBlur = (e) => {
    setFocus(false);
    onBlur(e);
  };
  const handleChange = (e) => {
    onChange(e);
  };
  const displayMsg = () => {
    if (error && !focus) return error;
    if (info && focus) return info;
    return null;
  };

  const showAlert = (error && !focus) || (info && focus);

  return (
    <div
      css={styles}
      className={`${className} ${focus && info ? 'info' : ''} ${error ? 'error' : ''}`}
    >
      {label && (
        <label className="label">
          {required && <span className="required">*</span>} {label}
        </label>
      )}
      <div className="input-container">
        {icon && (
          <ButtonWrapper onClick={onIconClick} className="input-icon">
            <Icon icon={icon} color={colors.focus} size={24} />
          </ButtonWrapper>
        )}
        {chooseRender(
          Tag,
          mask,
          render,
        )({
          value,
          ...props,
          onBlur: handleBlur,
          onFocus: handleFocus,
          disabled,
          onChange: handleChange,
        })}
      </div>
      {children}
      {showAlert && (
        <span className="message">
          <Icon
            icon={focus && info ? icons.info : icons.error}
            color={focus ? colors.focus : colors.error}
          />
          <span>{displayMsg()}</span>
        </span>
      )}
    </div>
  );
};

Input.propTypes = {
  tag: PropTypes.string,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string.isRequired,
  icon: PropTypes.string,
  info: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onIconClick: PropTypes.func,
  children: PropTypes.node,
  render: PropTypes.func,
  mask: PropTypes.string,
};

Input.defaultProps = {
  tag: 'input',
  info: null,
  error: null,
  disabled: false,
  required: false,
  onFocus: () => {},
  onBlur: () => {},
  onChange: () => {},
  onIconClick: () => {},
  className: '',
  icon: undefined,
  children: null,
  render: null,
  mask: null,
};

export default Input;
